import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Fancybox } from '@fancyapps/ui'

const PaymentLink = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getPaymentLinks, paymentLinks } = useAllContext()
    

    // pagination
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8
    useEffect(() => {
        if (paymentLinks) {
            // Calculate the total number of pages
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(paymentLinks.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(paymentLinks.length / itemsPerPage));
        }
        
    }, [itemOffset, itemsPerPage, paymentLinks]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % paymentLinks.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    // end pagination

    const approveLink = async (linkId) => {
        const linkData = {
            linkId: `${linkId}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/approve-payment-link`, linkData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getPaymentLinks(adminInfo.token)
            }


        } catch (error) {
            console.log('Error:', error);
        }
        
    }

    useEffect(() => {
        getPaymentLinks(adminInfo.token)
    }, [])

    Fancybox.bind("[data-fancybox='']", {
        Thumbs: {
            autoStart: false,
        },
    });

    const openImage = (url) => {
        Fancybox.show([{ src: url, type: 'image' }]);
    }

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">All Payment Links</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{"width": "50px"}}>#</th>
                                                        <th scope="col" style={{"width": "80px"}}>Image</th>
                                                        <th scope="col">Product Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Payment type</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Currency</th>
                                                        <th scope="col">Date & Time</th>
                                                        <th scope="col">Status</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1}</td>
                                                                <td>
                                                                    {
                                                                        items.productImage ? <>
                                                                            <Link onClick={ () => openImage(`${process.env.REACT_APP_IMAGES_URL}/${items.productImage}`) } to={"#"} data-fancybox=""><img src={`${process.env.REACT_APP_IMAGES_URL}/${items.productImage}`} className="img-fluid rounded" alt="" style={{ "width": "80px", "minWidth": "80px" }} /></Link>
                                                                        </>
                                                                        : <img src={require('../assets/images/default.jpg')} className="img-fluid rounded me-3" alt="" style={{"width": "80px", "minWidth": "80px"}} />
                                                                    }
                                                                </td>
                                                                <td>{items.productName}</td>
                                                                <td>{items.email}</td>
                                                                <td>{items.paymentType}</td>
                                                                <td>{items.price}</td>
                                                                <td>{items.currency}</td>
                                                                <td>
                                                                    {new Date(items.createdAt).toLocaleTimeString()} <br />
                                                                    {new Date(items.createdAt).toLocaleDateString()}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        items.linkStatus=="pending" ? <div className="dropdown dropdown1">
                                                                            <button className={`btn badge fs-14 fw-medium px-2 py-2 dropdown-toggle ${items.linkStatus == "pending" ? 'pending':'approve' }`} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                {items.linkStatus}
                                                                            </button>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                <li><Link className="dropdown-item" onClick={() => approveLink(items.linkId)}>Approve</Link></li>
                                                                            </ul>
                                                                        </div> :
                                                                        <span className={`badge p-2 fs-14 fw-medium ${items.linkStatus == "pending" ? 'pending':'approve' }`}>{items.linkStatus}</span>
                                                                    }
                                                                </td>
                                                                {/* <td><span className={`badge fs-14 fw-medium ${items.linkStatus == "pending" ? 'pending':'approve' }`}>{items.linkStatus}</span></td> */}
                                                                {/* <td className="text-center"><button type="submit" onClick={() => { deleteAccount(items.id) }} className="btn btn1 py-1 px-2 bg-danger border-danger text-white" style={{ "minWidth": "auto" }}><FaRegTrashAlt /></button></td> */}
                                                            </tr>
                                                        })
                                                            :
                                                            <tr>
                                                                <td colSpan={15}><p className='text-center mt-4'>No Records</p></td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-12'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default PaymentLink