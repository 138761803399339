import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

import { Fancybox } from '@fancyapps/ui';

const WidgetTransactions = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getWidgetTransactions, widgetTransactions } = useAllContext()
    

    const acceptedLink = async (id, status, transactionNum) => {
        const sendData = {
            id: `${id}`,
            status: status
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/update-widget-transaction-status`, sendData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getWidgetTransactions(adminInfo.token)
            }


        } catch (error) {
            console.log('Error:', error);
        }


        const apiData = {
            action: "deposit",
            TransactionNumber: transactionNum,
            status: status
        }

        try {
            const resp = await axios.post(`https://hook-dragon.axbbet.com/hook/v1/onepay/notify`, apiData)
            const data = await resp.data
            const dataDetails = JSON.parse(data)
            
            if (dataDetails.status === 'OK') {
                toast.success("Resend Successfully!")
            }

        } catch (error) {
            console.log('Error:', error);
        }
        
    }

    const statusUpdate = async (id) => {
        const sendData = {
            id: `${id}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/set-widget-transaction-sending`, sendData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getWidgetTransactions(adminInfo.token)
            }


        } catch (error) {
            console.log('Error:', error);
        }
        
    }


    // pagination
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8
    useEffect(() => {
        if (widgetTransactions) {
            // Calculate the total number of pages
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(widgetTransactions.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(widgetTransactions.length / itemsPerPage));
        }
        
    }, [itemOffset, itemsPerPage, widgetTransactions]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % widgetTransactions.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    // end pagination

    useEffect(() => {
        getWidgetTransactions(adminInfo.token)
    }, [])

    Fancybox.bind("[data-fancybox='']", {
        Thumbs: {
            autoStart: false,
        },
    });

    const openImage = (url) => {
        Fancybox.show([{ src: url, type: 'image' }]);
    }

    return (
        <>
            <div className='main-content'>
                <Header pageName={'WidgetTransactions'} />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Widget Transactions</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{"width": "50px"}}>#</th>
                                                        <th scope="col" style={{"width": "80px"}}>Payment Proof</th>
                                                        <th scope="col">Widget Code</th>
                                                        <th scope="col">Transaction Number</th>
                                                        <th scope="col">Payment Method</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Account Number</th>
                                                        <th scope="col">First Name</th>
                                                        <th scope="col">Last Name</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Date & Time</th>
                                                        <th scope="col">Accepted</th>
                                                        <th scope="col">Status</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1}</td>
                                                                <td>
                                                                    {
                                                                        items.paymentSlip ? <>
                                                                            <Link onClick={ () => openImage(`${process.env.REACT_APP_IMAGES_URL}/${items.paymentSlip}`) } to={"#"} data-fancybox=""><img src={`${process.env.REACT_APP_IMAGES_URL}/${items.paymentSlip}`} className="img-fluid rounded" alt="" style={{ "width": "80px", "minWidth": "80px" }} /></Link>
                                                                        </>
                                                                        : <img src={require('../assets/images/default.jpg')} className="img-fluid rounded me-3" alt="" style={{"width": "80px", "minWidth": "80px"}} />
                                                                    }
                                                                </td>
                                                                <td>{items.widgetCode}</td>
                                                                <td>{items.transactionNumber}</td>
                                                                <td>{items.paymentMethod}</td>
                                                                <td>{items.bankName}</td>
                                                                <td>{items.accountNumber}</td>
                                                                <td>{items.firstName}</td>
                                                                <td>{items.lastName}</td>
                                                                <td>
                                                                    {items.paymentMethod === "Crypto" && items.currency === "BTC" && `${items.priceBTC} BTC`}
                                                                    {items.paymentMethod === "Crypto" && items.currency === "USDT" && `${items.priceUSDT.toFixed(2)} USDT`}
                                                                    {items.paymentMethod === "PromptPay" && `${items.priceTHB.toFixed(2)} THB`}
                                                                    {items.paymentMethod === "Bank Transfer" && `${items.priceTHB.toFixed(2)} THB`}
                                                                </td>
                                                                <td>
                                                                    {new Date(items.createdAt).toLocaleTimeString()} <br />
                                                                    {new Date(items.createdAt).toLocaleDateString()}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        items.transactionStatus=="completed" ? <div className="dropdown dropdown1">
                                                                            <button className={`btn badge fs-14 fw-medium px-2 py-2 dropdown-toggle ${items.transactionStatus=="completed" ? 'yes':'no'}`} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                {items.transactionStatus === "completed" ? 'pending' : items.transactionStatus}
                                                                            </button>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                <li><Link className="dropdown-item" onClick={() => acceptedLink(items.id, "approved", items.transactionNumber)}>Approve</Link></li>
                                                                                <li><Link className="dropdown-item" onClick={() => acceptedLink(items.id, "rejected", items.transactionNumber)}>Reject</Link></li>
                                                                            </ul>
                                                                        </div> :
                                                                        <span className={`badge p-2 fs-14 fw-medium ${items.transactionStatus=="approved" ? 'approve':'no'}`}>{items.transactionStatus}</span>
                                                                    }
                                                                    
                                                                </td>
                                                                
                                                                <td>
                                                                    {
                                                                        items.status === "pending" ? 
                                                                        <Link className={`badge p-2 fs-14 fw-medium yes`} onClick={() => statusUpdate(items.id)}>Change to Sending</Link>
                                                                        : <span className={`badge p-2 fs-14 fw-medium`}>-</span>
                                                                        // : <span className={`badge p-2 fs-14 fw-medium approve`}>{items.status}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={15}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-12'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default WidgetTransactions